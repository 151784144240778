export const baseValidation = ({ validation, form, property }) => {
  if (property) {
    // Validate one item
    if (typeof form[property] === 'string') {
      validation[property] = form[property] ? true : false
    }
    if (typeof form[property] === 'object') {
      Object.keys(form[property]).length ? validation[property] = true : validation[property] = false
    }
  } else {
    // Validate all items
    for (const key in validation) {
      if (validation.hasOwnProperty(key)) {
        if (typeof form[key] === 'string') {
          validation[key] = form[key] ? true : false
        }
        if (typeof form[key] === 'object') {
          Object.keys(form[key]).length ? validation[key] = true : validation[key] = false
        }
      }
    }
  }
  if (Object.values(validation).includes(false)) return false
  else return true
}
