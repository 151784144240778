<template>
  <section>
    <app-card
      @admit="onEdit({ ...$route.params })"
      @decline="onCancel"
    >
      <template #body>
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-6 mb-1 mt-1">
              <span>ID: {{ $route.params.id }}</span>
            </div>
            <div class="col-12 col-lg-6 mb-1 mt-0 mt-lg-1">
              <span>Дата регистрации: {{ created_at }}</span>
            </div>
            <div class="col-12 col-lg-6">
              <label for="staff-name">ФИО</label>
              <b-form-input
                id="staff-name"
                :state="validation.full_name"
                v-model="form.full_name"
                class="mb-1"
                @change="onValidate({ ...$data, property: 'full_name' })"
              ></b-form-input>
            </div>
            <div class="col-12 col-lg-6">
              <label for="staff-phone">Телефон</label>
              <b-form-input
                ref="phone"
                :state="validation.phone"
                id="staff-phone"
                v-mask="phoneMask"
                v-model="form.phone"
                class="mb-1"
                @input="onValidate({ ...$data, property: 'phone' })"
              ></b-form-input>
            </div>
          </div>
        </div>
      </template>
    </app-card>
    <app-total
      :item-count="itemCount"
      :total-price="totalPrice"
    ></app-total>

    <table-body
      v-bind="{
        currentPage: page,
        pageCount,
        itemCount,
        pageSize: page_size,
        fields,
      }"
      @update:page-size="onPerPageChange"
      @update:page="onPageChange"
      @settings-confirm="updateTableConfig"
      is-column-settings
    >
      <b-table
        class="position-relative"
        v-bind="{
          items: orders,
          fields,
          striped,
          hover,
          responsive,
          emptyText,
          showEmpty,
          perPage: page_size,
          busy: loading,
        }"
      >
        <template #cell(delivery_status)="{ item }">
          {{ deliveryTypeCollection.find(type => type.value === item.delivery_status).text }}
        </template>

        <template #cell(delivery_time)="{ item }">
          <p> с {{ $dayjs(item.delivery_interval_from * 1000).format('DD MMM HH:mm') }} </p>
          <p> по {{ $dayjs(item.delivery_interval_to * 1000).format('DD MMM HH:mm') }} </p>
        </template>

        <template #cell(client_phone)="{ item }">
          <a :href="`tel:+${item.client_phone}`">{{  item.client_phone | VMask('+#(###) ###-##-##') }}</a>
        </template>
        <template #cell(recipient_phone)="{ item }">
          <a :href="`tel:+${item.recipient_phone}`">{{  item.recipient_phone | VMask('+#(###) ###-##-##') }}</a>
        </template>

        <template #cell(status)="{ item }">
          <b-button-group style="width: 160px;">
            <b-button v-bind="{
              block: true,
              variant: style.status[item.status]
            }">
              {{ orderStatus[item.status] }}
            </b-button>
          </b-button-group>
        </template>

        <template #cell(composition_photo)="{ item }">
          <template v-if="item.composition_photo.length">
            <template v-for="(photo, index) in item.composition_photo">
              <div
                @click="onImage({ ...item })"
                :key="index"
                :class="index < item.composition_photo.length - 1 ? 'mb-1' : ''"
              >
                <b-img
                  v-bind="{
                    src: `${url}${photo.photo}`,
                    alt: '',
                    width: '100px',
                  }"
                  @click="activeImgUrl = `${url}${photo.photo}`"
                ></b-img>
              </div>
            </template>
          </template>
        </template>

        <template #cell(source)="{ item }">
          {{ sources.find(i => i.id === item.source_id)
            ? sources.find(i => i.id === item.source_id).name
            : '' }}
        </template>

        <template #cell(pay_status)="{ item }">
          {{ orderPaymentStatusCollection.find(status => status.value === item.pay_status)
            ? orderPaymentStatusCollection.find(status => status.value === item.pay_status).text
            : '' }}
        </template>

        <template #cell(manager)="{ item }">
          {{ item.manager_full_name }}
        </template>

        <template #cell(courier)="{ item }">
          {{ item.courier_full_name }}
        </template>

        <template #cell(actions)="{ item }">
          <table-buttons
            v-bind="{
              disabled: hasPermission,
            }"
            @edit="onOrderEdit({ ...item })"
            @delete="onOrderDelete({ ...item })"
          ></table-buttons>
        </template>
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <span>Загрузка...</span>
          </div>
        </template>
      </b-table>
    </table-body>
    <common-dialog
      v-bind="{
        title: modal.delete.visible
          ? modal.delete.title
          : modal.image.visible
            ? modal.image.title
            : modal.create.visible
             ? modal.create.title
             : modal.edit.title,
        value: modal.delete.visible ||
          modal.image.visible ||
          modal.edit.visible ||
          modal.create.visible,
        okOnly: modal.image.visible,
        okTitle: modal.delete.visible
          ? modal.delete.okTitle
          : modal.image.visible
            ? modal.image.okTitle
            : modal.edit.okTitle,
        hideHeader: !modal.create.visible && !modal.delete.visible,
        hideFooter: !modal.create.visible && !modal.delete.visible,
        size: modal.image.visible ? 'md' : '',
        modalClass: modal.image.visible ? 'app-modal-img' : '',
      }"
      @confirm="onConfirm"
    >
      <template v-if="modal.delete.visible">
        <p> {{ `${modal.delete.content} ${order_id}?`}} </p>
      </template>
      <template v-if="modal.image.visible">
        <div><img :src="activeImgUrl" /></div>
      </template>
    </common-dialog>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { throttle } from 'lodash'

import { AuthNameSpace, RoleCollection } from '@/store/modules/auth/types'
import { ClientNameSpace, ClientActionTypes } from '@/store/modules/client/types'
import { OrderNameSpace, OrderActionTypes } from '@/store/modules/order/types'
import { SourceNameSpace, SourceActionTypes } from '@/store/modules/source/types'

import TableBody from '@/components/common/table/TableBody'
import TableButtons from '@/components/common/buttons/TableButtons'
import AppCard from '@/components/common/pages/Card'
import AppTotal from '@/components/common/table/OrdersTotal.vue'

import { baseValidation } from '@/utils/validation'

import { duration, phoneMask, BASE_URL } from '@/config'

import { columns, modal } from '@/config/client_card'

import { tableConfigSetup, tableConfigFormatBeforeSave } from '@/mixins/table-columns-controller'
import { phoneToMaskView } from '@/utils/phone-format'

import { deliveryTypeCollection, OrderStatus, orderPaymentStatusCollection } from '@/config/shared'

export default {
  name: 'ClientCard',
  mixins: [tableConfigSetup, tableConfigFormatBeforeSave],
  components: {
    AppCard,
    TableBody,
    TableButtons,
    AppTotal,
    CommonDialog: () => import('@/components/common/common-dialog'),
  },
  data() {
    return {
      client_id: this.$route.params.id,
      order_id: '',
      created_at: '',
      form: {
        full_name: '',
        phone: '',
      },
      remove_completed_and_approved_filter: 1,
      time: false,
      page_size: 10,
      page: 1,
      columns: null,
      columnsUpdateSetup: null,
      phoneMask,
      validation: {
        full_name: null,
        phone: null,
      },
      modal,
      loading: true,
    }
  },
  props: {
    striped: {
      type: Boolean,
      default() {
        return true
      },
    },
    hover: {
      type: Boolean,
      default() {
        return true
      },
    },
    responsive: {
      type: Boolean,
      default() {
        return true
      },
    },
    emptyText: {
      type: String,
      default() {
        return 'Нет данных'
      },
    },
    showEmpty: {
      type: Boolean,
      default() {
        return true
      },
    },
    url: {
      type: String,
      default() {
        return BASE_URL
      },
    },
  },
  computed: {
    ...mapState(['loaded']),
    ...mapState(AuthNameSpace, {
      userData: 'userData',
    }),
    ...mapState(ClientNameSpace, {
      client: 'client',
      config: 'config',
    }),
    ...mapState(OrderNameSpace, {
      orders: 'orders',
      pageCount: 'pageCount',
      itemCount: 'itemCount',
      totalPrice: 'totalPrice',
    }),
    ...mapState(SourceNameSpace, {
      sources: 'sources',
    }),
    fields() {
      return this.tableConfigSetup({ local_config: columns, response_config: this.config })
    },
    phoneSerialized() {
      return this.form.phone.replace(/\D/g, '')
    },
    deliveryTypeCollection() {
      return deliveryTypeCollection
    },
    orderPaymentStatusCollection() {
      return orderPaymentStatusCollection
    },
    style() {
      return {
        status: {
          0: 'light',
          1: 'success',
          2: 'info',
          3: 'warning',
          4: 'primary',
        },
      }
    },
    orderStatus() {
      return OrderStatus
    },
    hasPermission() {
      return this.userData.role === RoleCollection.florist || this.userData.role === RoleCollection.courier
    },
  },
  async created() {
    await this.loadTableConfig()
    await this.editInit({ ...this.$route.params })
    await this.loadOrderCollection()
    await this.loadSourceCollection()
  },
  methods: {
    ...mapActions(ClientNameSpace, {
      [ClientActionTypes.LoadClient]: ClientActionTypes.LoadClient,
      [ClientActionTypes.UpdateClient]: ClientActionTypes.UpdateClient,
      [ClientActionTypes.LoadClientTableConfig]: ClientActionTypes.LoadClientTableConfig,
      [ClientActionTypes.UpdateClientTableConfig]: ClientActionTypes.UpdateClientTableConfig,
    }),
    ...mapActions(OrderNameSpace, {
      [OrderActionTypes.LoadOrderCollection]: OrderActionTypes.LoadOrderCollection,
      [OrderActionTypes.LoadOrder]: OrderActionTypes.LoadOrder,
      [OrderActionTypes.DeleteOrder]: OrderActionTypes.DeleteOrder,
    }),
    ...mapActions(SourceNameSpace, {
      [SourceActionTypes.LoadSourceCollection]: SourceActionTypes.LoadSourceCollection,
    }),

    loadClient: throttle(async function({ id }) {
      await this[ClientActionTypes.LoadClient]({ id })
    }, duration),
    loadOrderCollection: throttle(async function() {
      try {
        this.loading = true
        await this[OrderActionTypes.LoadOrderCollection]({ ...this.$data })
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    }, duration),
    loadOrder: throttle(async function({ id }) {
      await this[OrderActionTypes.LoadOrder]({ id })
    }, duration),

    async loadSourceCollection() {
      await this[SourceActionTypes.LoadSourceCollection]({ page: 1, page_size: 100 })
    },

    async loadTableConfig() {
      await this[ClientActionTypes.LoadClientTableConfig]()
    },

    async updateTableConfig({ settings }) {
      await this[ClientActionTypes.UpdateClientTableConfig]({ settings: this.tableConfigFormatBeforeSave({ settings }) })
    },

    async onEdit({ id }) {
      if (this.onValidate({ ...this.$data })) {
        await this[ClientActionTypes.UpdateClient]({
          id,
          ...this.form,
          phone: this.phoneSerialized,
        })
      }
    },

    async onConfirm({ confirmed }) {
      if (confirmed) {
        try {
          if (this.modal.edit.visible) {
            await this[OrderActionTypes.UpdateOrder]({ ...this.form })
            await this.loadOrderCollection()
          }

          if (this.modal.delete.visible) {
            await this[OrderActionTypes.DeleteOrder]({ id: this.order_id })

            if (this.orders.length === 1 && this.page !== 1) this.page -= 1

            await this.loadOrderCollection(this.$route.params)
          }
        } catch {
          for (const type in this.modal) {
            if (this.modal[type].visible) {
              this.modal[type].visible = false
            }
          }
        }
      }
      for (const type in this.modal) {
        if (this.modal[type].visible) {
          this.modal[type].visible = false
        }
      }
    },

    onImage({ photo }) {
      this.photo = photo

      this.modal.image.visible = true
    },

    async editInit({ id }) {
      await this.loadClient({ id })
      const { full_name, phone } = this.client
      this.form = { full_name, phone: this.phoneToMaskView({ phone: phone }) }
      this.created_at = this.$dayjs(this.client.created_at * 1000).format('DD.MM.YYYY')
    },

    onCancel() {
      this.$router.push('/clients')
    },

    onOrderEdit({ id }) {
      this.$router.push({ name: 'order-base-item', params: { id } })
    },

    onOrderDelete({ id }) {
      this.order_id = id

      this.modal.delete.visible = true
    },

    onSettings({ fields }) {
      this.tableColumns = fields
    },
    onValidate({ validation, form, property }) {
      const result = baseValidation({ validation, form, property })
      return result
    },
    async onPerPageChange(count) {
      this.page = 1
      this.page_size = parseInt(count, 10)
      await this.loadOrderCollection()
    },

    async onPageChange(count) {
      this.page = parseInt(count, 10)

      await this.loadOrderCollection()
    },
    phoneToMaskView({ phone }) {
      return phoneToMaskView({ phone })
    },
  },
}
</script>
